import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import { composeClasses, jsonId } from 'utils'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SocialLinks from 'components/socialLinks'
import { graphql, Link } from 'gatsby'
import Accordion from '../components/accordion'
import HelmetExport from 'react-helmet'
import * as sharedStyles from '../styles/sharedLayout.module.css'
import {
  headerSection,
  testimonialAuthor,
  testimonialAvatar,
  testimonialAuthorName,
  testimonialAuthorJobTitle,
  testimonialAuthorNameMobile,
  testimonialAuthorCountry
}
  from '../styles/careers.module.css'
import ContentSection from 'components/contentSection'
import CtaBlocks from 'components/ctaBlocks'
import parse from 'html-react-parser'
import BookingCtas from 'components/bookingCtas'
import TestimonialBlocks from 'components/testimonialBlocks'
import quoteLeft from 'images/quote-left.svg'
import { isMobile } from 'react-device-detect'
import CarouselComponent from 'components/carousel'
import RegionsServed from 'components/regionsWeServe'
import {
  bookASlotPlanSection,
  customerSuccessWrapper,
  customerSuccessHeader,
  customerSuccessContainer,
  customerSuccessImageWrapper,
  paddedContainer,
  successStoryDesc,
  customerSuccessDivDesktop,
  customerSuccessDivMobile,
  customerSuccessButtonWrapper,
  customerSuccessButton,
  customerSuccessStoriesContainer,
  googlePartnerBadge,
  googleWorkspaceWrapper,
  googleWorkspaceContainer,
  headingWrapper,
  headingContainer,
  planListContainer,
  planWrapper,
  planStyle,
  planStyleContainer,
  planTitleFlexContainer,
  planPriceStyles,
  contentDescriptionStyle,
  socialIconContainer,
  successListContainer,
  workspaceSectionColumn,
  whatWorkspaceCanDoSection,
  workspaceSocialLinkContainer,
  workspaceQuestionsSection,
  workspaceQuestionsColumns,
  workspaceQuestionsColumnsHeader,
  workspaceQuestionsColumnsHeaderTwo,
  whyDeimosFlexContainer,
  bookASlotWrapper,
  bookASlotBodyContainer,
  bookASlotContent,
  bookASlotCta,
  bookASlotCtaInner,
  bookASlotPlanSectionInner,
  calendarWrapper,
  calendarSubtitleStyles,
  calendarTitleStyle,
  planSection,
  planSpacer,
  planTitleStyles,
  planHeaderStyles,
  planIcons,
  planColumnSection,
  customerTestimonialColumns,
  testimonialSection,
  testimonialBlock,
  carouselBlock,
  customerCarousel,
  accordionSection,
  faqSectionHeader
} from '../styles/workspace.module.css'
import {
  testimonialTopSection,
  testimonialImg,
  cardHeader,
  cardName,
  cardRole,
  quoteContainer,
  quoteStyleLeft,
  quoteStyleRight
} from '../styles/internship.module.css'
import { accordion } from '../styles/accordion.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "google workspace" }) {
        edges {
          node {
            googleWorkspaceFieldGroup {
              googleWorkspaceHeader
              googleWorkspaceHeaderImage {
                sourceUrl
              }
              googleWorkspaceDescription
              gwBookSlotText
              gwWhatsappText
              gwWhatsappLink
              whatWorkspaceCanDoHeader
              whatWorkspaceCanDo {
                workspaceGainsHeader
                workspaceGainsDescription
              }
              workspaceQuestionsHeader
              workspaceQuestionsSection {
                workspaceQuestionsHeading
                workspaceQuestionsHyperlink
                workspaceQuestionsHyperlinkText
              }
              whyDeimosHeader
              whyDeimosContent {
                whyDeimosContentNumber
                whyDeimosContentHeader
                whyDeimosContentDescription
              }
              bookASlotSectionHeader
              bookASlotSection
              calendarUrl
              customerSuccessHeader
              customerSuccessStories {
                customerSuccessButtonLink {
                  link
                }
                customerSuccessButtonText
                customerSuccessContent
                customerSuccessIcon {
                  sourceUrl
                }
                customerSuccessSubtitle
                customerSuccessTitle
              }
              planSectionTitle
              planSectionHeader
              planRepeater {
                planTitle
                planTagText
                planPrice
                planUserSub
                planDescription
              }
              planCalendarTitle
              planCalendarSubtitle
              faqSectionHeader
              faqsContent {
                faqsContentNumber
                faqsContentHeading
                faqsContentDescription
              }
              customersTestimonialHeader
              customersTestimonialSection {
                customerTestimonialRole
                customerTestimonialQuote
                customerTestimonialName
                customerTestimonialImage {
                  sourceUrl
                }
              }
              regionsServedHeader
              regionsServedContent {
                regionContentHeading
                regionContentItems
              }
              regionsServedMap {
                sourceUrl
              }
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const GoogleWorkspacePage = ({ data, pageContext }) => {
  const pageData = data.wordPress.pages.edges[0].node.googleWorkspaceFieldGroup
  const workspacePartnerbadges = pageData.googleWorkspaceHeaderImage.sourceUrl
  const whatWorkspaceCanDo = pageData.whatWorkspaceCanDo
  const whatWorkspaceCanDoHeader = pageData.whatWorkspaceCanDoHeader
  const workspaceQuestionsHeader = pageData.workspaceQuestionsHeader
  const workspaceQuestions = pageData.workspaceQuestionsSection
  const whyDeimosHeader = pageData.whyDeimosHeader
  const whyDeimosData = pageData.whyDeimosContent
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const faqsContent = pageData.faqsContent
  const calendarUrl = pageData.calendarUrl
  const successStories = pageData.customerSuccessStories
  const planData = pageData.planRepeater
  const customersTestimonial = pageData.customersTestimonialSection
  const regionsHeader = pageData.regionsServedHeader
  const regionsContent = pageData.regionsServedContent
  const regionsMap = pageData.regionsServedMap.sourceUrl
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug

  const {
    breadcrumb: { crumbs }
  } = pageContext

  const planList = ({ planData } = []) => {
    return (
      planData.length &&
      planData.map((item, index) => {
        return (
          <div
            className={planStyle}
            key={index}>
            <div className={planStyleContainer}>
              <div className={planTitleFlexContainer}>
                <h4>{item.planTitle}</h4>
                <span>{parse(item.planTagText)}</span>
              </div>
              <span className={planListContainer}>{parse(item.planDescription)}</span>
            </div>
            <div className={planPriceStyles}>
              <h1>{item.planPrice}</h1>
              {parse(item.planUserSub)}
            </div>
          </div>
        )
      })
    )
  }

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='This page explains Deimos’ relationship with Google.'
        title='Google Workspace'
      />
      <div className={googleWorkspaceWrapper}>
        <div className={googleWorkspaceContainer}>
          <Breadcrumb
            crumbLabel='Reseller'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
          <div className={headingWrapper}>
            <div className={headingContainer}>
              <h1>{pageData.googleWorkspaceHeader}</h1>
              {parse(pageData.googleWorkspaceDescription)}
              <BookingCtas
                calendarUrl={calendarUrl}
                pageData={pageData} />
            </div>
            <img
              alt='google partner badge'
              className={googlePartnerBadge}
              src={workspacePartnerbadges}
            />
          </div>
        </div>
        <div className={whatWorkspaceCanDoSection}>
          <ContentSection
            backgroundColor={'#E5E5E5'}
            isFlex={false}
            items={whatWorkspaceCanDo}
            render={(item, index) => {
              const { workspaceGainsDescription, workspaceGainsHeader } = item
              return (
                <div
                  className={workspaceSectionColumn}
                  key={index.toString()}
                >
                  <h5>{workspaceGainsHeader}</h5>
                  {parse(workspaceGainsDescription)}
                </div>
              )
            }}
            title={whatWorkspaceCanDoHeader}
          />
        </div>
        <div className={workspaceQuestionsSection} >
          <CtaBlocks
            items={workspaceQuestions}
            render={(item, index) => {
              const { workspaceQuestionsHeading, workspaceQuestionsHyperlink, workspaceQuestionsHyperlinkText } = item
              return (
                <div
                  className={workspaceQuestionsColumns}
                  key={index.toString()}>
                  <h3
                    className={
                      workspaceQuestionsHeading.includes('migrate')
                        ? workspaceQuestionsColumnsHeaderTwo
                        : workspaceQuestionsColumnsHeader}
                  >
                    {workspaceQuestionsHeading}
                  </h3>
                  <Link
                    to={`/google-workspace/${workspaceQuestionsHyperlink.toLowerCase()}`}>
                    {workspaceQuestionsHyperlinkText}
                  </Link>
                </div>
              )
            }}
            title={workspaceQuestionsHeader}
          />
        </div>
        <div className={whatWorkspaceCanDoSection}>
          <ContentSection
            backgroundColor={'#E5E5E5'}
            items={whyDeimosData}
            render={(item, index) => {
              const { whyDeimosContentDescription, whyDeimosContentHeader, whyDeimosContentNumber } = item
              return (
                <div
                  className={workspaceSectionColumn}
                  key={index.toString()}
                >
                  <div className={whyDeimosFlexContainer}>
                    <span>{parse(whyDeimosContentNumber)}</span>
                    <h5>{whyDeimosContentHeader}</h5>
                  </div>
                  <span className={contentDescriptionStyle}>{parse(whyDeimosContentDescription)}</span>
                </div>
              )
            }}
            title={whyDeimosHeader}
          />
        </div>
        <div className={bookASlotWrapper}>
          <div className={bookASlotBodyContainer}>
            <div className={bookASlotContent}>
              {parse(pageData.bookASlotSectionHeader)}
              <span>{parse(pageData.bookASlotSection)}</span>
            </div>
            <div className={bookASlotCta}>
              <div className={bookASlotCtaInner}>
                <BookingCtas
                  calendarUrl={calendarUrl}
                  isColumn
                  isFlex
                  pageData={pageData} />
              </div>
            </div>
          </div>
        </div>

        <div className={customerSuccessWrapper}>
          <h2 className={customerSuccessHeader}>
            {pageData.customerSuccessHeader}
          </h2>
          <div className={customerSuccessStoriesContainer}>
            {successStories.map((item, index) => {
              return (
                <div
                  className={customerSuccessContainer}
                  key={index.toString()}>
                  <div className={customerSuccessImageWrapper}>
                    <img src={item.customerSuccessIcon.sourceUrl} />
                  </div>
                  <div className={paddedContainer}>
                    <div className={successStoryDesc}>
                      <h4>
                        {item.customerSuccessTitle}
                      </h4>
                      <p>
                        {parse(item.customerSuccessSubtitle)}
                      </p>
                    </div>
                    <div className={customerSuccessDivDesktop}>
                      <span className={successListContainer}>{parse(item.customerSuccessContent)}</span>
                    </div>
                    <div className={customerSuccessDivMobile}>
                      <span className={successListContainer}>{parse(item.customerSuccessContent.substring(0, 115) + '...')}</span>
                    </div>
                    <div className={customerSuccessButtonWrapper}>
                      <a
                        href={item.customerSuccessButtonLink.link}
                        rel='noopener noreferrer'
                        target={'_blank'}>
                        <span
                          className={customerSuccessButton}>
                          {item.customerSuccessButtonText}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <RegionsServed
          regionsContent={regionsContent}
          regionsHeader={regionsHeader}
          regionsMap={regionsMap} />
        <div className={planWrapper}>
          <div
            className={planSection}
            id='planSection'>
            <h2 className={planTitleStyles}>{pageData.planSectionTitle}</h2>
            <div className={planHeaderStyles}>{pageData.planSectionHeader}</div>
            <div className={planIcons} />
            <div className={planColumnSection}>
              {planList({ planData })}
            </div>
            <div className={calendarWrapper}>
              <div className={calendarTitleStyle}>
                <h3>{pageData.planCalendarTitle}</h3>
              </div>
              <div className={bookASlotPlanSection}>
                <div className={bookASlotPlanSectionInner}>
                  <p className={calendarSubtitleStyles}>
                    {pageData.planCalendarSubtitle}
                  </p>
                  <div className={planSpacer}>
                    <BookingCtas
                      calendarUrl={calendarUrl}
                      isColumn
                      isFlex
                      pageData={pageData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={accordionSection}>
          <h2 className={faqSectionHeader}>{pageData.faqSectionHeader}</h2>
          <div className={accordion}>
            {faqsContent.map((item, index) => (
              <Accordion
                content={parse(item.faqsContentDescription)}
                contentTitle={item.faqsContentHeading}
                key={index.toString()}
                numbers={item.faqsContentNumber}
              />
            ))}
          </div>
        </div>
        <div className={socialIconContainer}>
          <div className={testimonialBlock}>
            <TestimonialBlocks
              items={customersTestimonial}
              render={(item, index) => {
                const { customerTestimonialRole, customerTestimonialQuote, customerTestimonialName, customerTestimonialImage } = item
                return (
                  <div
                    className={customerTestimonialColumns}
                    key={index.toString()}>
                    <div className={testimonialTopSection}>
                      <img
                        alt='Customer Testimonial Image'
                        className={testimonialImg}
                        height={62}
                        src={customerTestimonialImage.sourceUrl}
                        width={62}
                      />
                      <div className={cardHeader}>
                        <p className={cardName}>{customerTestimonialName}</p>
                        <p className={cardRole}>{customerTestimonialRole}</p>
                      </div>
                    </div>
                    <div className={quoteContainer}>
                      <img
                        className={quoteStyleLeft}
                        src={quoteLeft}
                      />
                      {parse(customerTestimonialQuote)}
                      <img
                        className={quoteStyleRight}
                        src={quoteLeft}
                      />
                    </div>
                  </div>
                )
              }}
              title={pageData.customersTestimonialHeader}
            />
          </div>
        </div>
        <div className={workspaceSocialLinkContainer}>
          <div className={carouselBlock}>
            <div className={composeClasses(sharedStyles.verticalContainer, testimonialSection)}>
              <div className={composeClasses(sharedStyles.verticalContainerInner, headerSection)}>
                <h2>{pageData.customersTestimonialHeader}</h2>
                <CarouselComponent
                  items={customersTestimonial}
                  itemsToShow={1}
                  render={(item, index) => {
                    const { customerTestimonialRole, customerTestimonialQuote, customerTestimonialName, customerTestimonialImage } = item
                    return (
                      <div
                        className={customerCarousel}
                        key={index}
                        style={isMobile && customersTestimonial.length < 3 ? { maxWidth: '100%' } : ''}
                      >
                        <div className={testimonialAuthor}>
                          <img
                            className={testimonialAvatar}
                            src={customerTestimonialImage && customerTestimonialImage.sourceUrl}
                          />
                          <div>
                            <span className={testimonialAuthorName}>{`${customerTestimonialName}`}</span>
                            <p className={testimonialAuthorJobTitle}>{customerTestimonialRole}</p>
                            <span className={testimonialAuthorNameMobile}><strong> {`${customerTestimonialName}`} </strong> </span>
                            <br className={testimonialAuthorNameMobile} />
                            <span className={testimonialAuthorCountry}>{customerTestimonialRole}</span>
                          </div>
                        </div>
                        <img
                          className={quoteStyleLeft}
                          src={quoteLeft}
                        />
                        <span>{parse(customerTestimonialQuote)}</span>
                        <img
                          className={quoteStyleRight}
                          src={quoteLeft}
                        />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <SocialLinks
            hasWhiteBackground
            socialLinks={footerData.socialLinks} />
        </div>
      </div>
    </>
  )
}

GoogleWorkspacePage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default GoogleWorkspacePage
