import React from 'react'
import PropTypes from 'prop-types'
import {
  sectionWrapper,
  sectionTitle,
  section
} from 'styles/testimonialBlocks.module.css'

const TestimonialBlocks = ({ items, render, title }) => {
  return (
    <div className={sectionWrapper}>
      <div className={sectionTitle}>
        <h2>{title}</h2>
      </div>
      <div>
        <div className={section}>
          {render && items.map((item, index) => {
            return render(item, index)
          })
          }
        </div>
      </div>
    </div>
  )
}

TestimonialBlocks.propTypes = {
  items: PropTypes.array.isRequired,
  render: PropTypes.func,
  title: PropTypes.string.isRequired
}

export default TestimonialBlocks
